

new WOW({ offset: 70 }).init();


$(document).ready(function () {

	if ($("#HomepageMarker").length !== 0) {
		$(".category-links__link").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });

		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow zoomIn");
		}


	}

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.RotatorTestimonialContent').sameHeight();
		$('.BookButton').width($('.CheckButton').width());
		//$(".clients__carousel .carousel-inner").css("height", "auto");
		//$(".clients__carousel .carousel-inner").sameHeight();
	}

	$(window).on('resize',
		function () {
			setTimeout(fixHeights, 300);
		});

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	setTimeout(fixHeights, 300);

	$(".testimonials .carousel").attr("id", "testimonialCarousel");

	$('.clients__carousel').on('slide.bs.carousel', function (ev) {
		var dir = ev.direction == 'right' ? 'prev' : 'next';
		$('.clients__carousel').not('.sliding').addClass('sliding').carousel(dir);
	});
	$('.clients__carousel').on('slid.bs.carousel', function (ev) {
		$('.clients__carousel').removeClass('sliding');
	});


});



